import "./Signup.scss";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SocialSign from "../../components/general/socialsign/SocialSign";
import SignUpForm from "../../components/singupComponent/SignupForm";
import { useTranslation } from "react-i18next";
import SignTopPart from "../../components/general/signTopPart/SignTopPart";
import TransitionMenu from "../../components/general/transaltionMenu/TranslationMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Button } from "bootstrap";

const Signup = () => {
  const { t } = useTranslation();

  return (
    <div className="signup">
      <div className="leftPart">
        <img
          style={{
            position: "relative",
            width: "105%",
            left: "170px",
            top: "500px",
          }}
          src={require("../../assets/imgs/signup/book and hill.png")}
          alt="Guy with a book on hill"
        />
      </div>
      <div className="rightPart">
        <SignTopPart />
        <div className="signWapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <h1 style={{ alignSelf: "flex-start", marginLeft: "1rem" }}>
              {t("sign-up-form-header")}
            </h1>
            {/* 
            <div className="Buttons">
              <div
                role="button"
                onClick={() => console.log("do somthing please Google")}
                className="SocialBtn"
              >
                <FontAwesomeIcon className="fs-3 icon" icon={faGoogle} />
                {t("log-in-button-google")}
              </div>
              <div
                role="button"
                onClick={() => console.log("do somthing please Facebook")}
                className="SocialBtn"
              >
                <FontAwesomeIcon className="fs-3 icon" icon={faFacebook} />
                {t("log-in-button-facebook")}
              </div>
            </div>
             */}
          </div>
          <div className="orClass">
            <p>- OR -</p>
          </div>
          <SignUpForm />
          <div className="option">
            {t("sign-up-form-paragraph")}
            <span>
              <Link to="/login"> {t("sign-up-form-link")}</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
