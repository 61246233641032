import "./VideoControls.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nextBtn, prevBtn } from "../../../store/unitsSlice";
import { useDispatch, useSelector } from "react-redux";

const VideoControls = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { noNext, activeVideo } = useSelector((state) => state.units);

  return (
    <div className="video-controls">
      <div className="lesson-actions">
        <div className="lesson-actions-group ">
          <button
            style={{
              pointerEvents: noNext && "none",
              background: noNext && "#ccc",
            }}
            className="prev"
            onClick={() => dispatch(console.log("prev"))}
          >
            Previous
          </button>
          <div>
            <button className="light-button  pdf">
              <a
                className=""
                href={
                  "https://newbrains-edu.com/public/middle/lessons/pdfs/" +
                  activeVideo.pdf
                }
                target={"_blank"}
                style={{ borderRadius: "50px" }}
              >
                {t("video-controls-pdf")}
              </a>
            </button>
            <button
              className="btn  start"
              style={{
                color: "white",
                backgroundColor: "#00C8D5",
                padding: "10px 30px",
                borderRadius: "50px",
              }}
            >
              <Link to={`/test/${activeVideo.id}`} className="nav-link">
                {t("video-controls-start")}
              </Link>
            </button>
          </div>

          {/* pointer-events: none; */}
          {/* <button className='prev'>Previous</button> */}
          <button
            style={{
              pointerEvents: noNext && "none",
              background: noNext && "#ccc",
            }}
            className="success-button next"
            onClick={() => dispatch(nextBtn())}
          >
            {t("video-controls-next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoControls;
