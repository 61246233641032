import { Container } from "react-bootstrap";
// import "./TeacherComponent.scss";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import PageReload from "../../general/pageReload/PageReload";

const TeacherComponent = ({ teachers }) => {
  const { t } = useTranslation();

  const { lang } = useSelector((state) => state.currentLang);

  return (
    <>
      {teachers.map((teacher, id) => (
        <div
          key={id}
          className="card"
          style={{ width: "18rem", marginRight: "40px" }}
        >
          <img
            className="card-img-top mb-3"
            src={
              "https://newbrains-edu.com/public/middle/profileImages/teachers/" +
              teacher.image
            }
            height="40%"
            alt={teacher.name}
          />
          <div className="card-body" style={{ color: "#434D57" }}>
            <h5 className="card-title" style={{ fontWeight: "bold" }}>
              {teacher.name}
            </h5>
            <h6 className="card-title">Biology Teacher</h6>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "70%" }}>
              <p className="card-text"> middle school</p>
            </div>
            <div
              className="btn"
              style={{
                width: "40%",
                margin: "15px",
                backgroundColor: "#00c8d5",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <a
                href="#"
                style={{
                  width: "40%",
                  margin: "15px",
                  backgroundColor: "#00c8d5",
                  color: "white",
                  fontWeight: "bold",
                  alignSelf: "flex-start",
                }}
              >
                {t("subject-start")}
              </a>
            </div>
          </div>
        </div>
        // <div key={teacher.id} className="teacher">
        //   <img
        //     src={
        //       "https://newbrains-edu.com/public/middle/profileImages/teachers/" +
        //       teacher.image
        //     }
        //     alt={teacher.name}
        //     style={{ height: "200px" }}
        //   />
        //   <div className="teacher-info">
        //     <h4 className="name">{teacher.name}</h4>
        //     <p className="school fw-bold">High School</p> <br />
        //     <p className="school fw-bold">
        //       {teacher.subject.name} teacher
        //     </p>{" "}
        //     <br />
        //     <p className="school fw-bold">{teacher.subject.section}</p>
        //   </div>
        // </div>
      ))}
    </>
  );
};

export default TeacherComponent;
