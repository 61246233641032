import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";
import Sidebar from "../../components/general/sidebar/Sidebar";
import Navbar from "../../components/general/navbar/Navbar";
import Wrapper from "../../components/general/Wrapper/Wrapper";
import GoodMorning from "../../components/accountComponent/goodMorning/GoodeMorning";
import ClassProgressSection from "../../components/accountComponent/classProgressSection/ClassProgressSection";
import TimeSpentSection from "../../components/accountComponent/timeSpentSection/TimeSpentSection";
import CircularProgressSection from "../../components/accountComponent/circularProgressSection/CircularProgressSection";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";

const Portfolio = () => {
  const [allGrade, setAllGrade] = useState([]);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        "https://newbrains-edu.com/public/middle/api/auth/getQuizResult",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "heighNewbrainsToken"
            )}`,
          },
        }
      );

      setAllGrade([...data.result]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section
      style={{
        background: 'url("./imgs/profile/profileBK.png") no-repeat',
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <Wrapper>
        <Container>
          <Row>
            <Col className="col-12" style={{ marginTop: "70px" }}>
              <GoodMorning />
            </Col>
            <Col>
              <ul className="mt-5">
                {allGrade.map((item) => (
                  <div class="card mt-3  text-center" key={item.id}>
                    <div class="card-header">{item.quiz.title}</div>
                    <div class="card-body">
                      <blockquote class="blockquote mb-0">
                        <span style={{ color: "#080" }} className="fs-4">
                          {item.correct_questions_grade + " / "}
                        </span>
                        <span style={{ color: "#080" }} className="fs-4">
                          {item.total_grade}
                        </span>
                      </blockquote>
                    </div>
                  </div>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="py-5"></div>
      </Wrapper>
    </section>
  );
};

export default Portfolio;
