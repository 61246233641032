import { Container, Row, Col } from "react-bootstrap";
import Subject from "../../components/subjectsComponent/subject/subject";
import Sidebar from "../../components/general/sidebar/Sidebar";
import Wrapper from "../../components/general/Wrapper/Wrapper";
import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import PageReload from "../../components/general/pageReload/PageReload";
import { setCurrentSubject } from "../../store/currentSubjectSlice";
import { useDispatch } from "react-redux";
import Navbar from "../../components/general/navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArchive } from "@fortawesome/free-regular-svg-icons";
import "./subjects.scss";
import { useTranslation } from "react-i18next";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
const Subjects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const token = localStorage.getItem("heighNewbrainsToken");

  const [Subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");

  const payMethod = async (message) => {
    setMessage(message);
    try {
      const { data } = await axios.post(
        "https://newbrains-edu.com/public/middle/api/auth/user_pay",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLink(data.redirect_url);
    } catch (error) {
      console.log(error);
    }
  };

  const dataFetch = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const { data } = await axios.get(
        "https://newbrains-edu.com/public/middle/api/auth/getSubjects",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      data.message ? payMethod(data.message) : setSubjects([...data.subjects]);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, []);

  return (
    <div
      className="subjects"
      style={{
        display: "flex",
        flexDirection: "row",
        background: 'url("./imgs/subjects/subjectsBK.png") no-repeat',
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <div>
        <Navbar />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <LoginNavbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div
              style={{
                backgroundColor: "#ddf3ff",
                borderRadius: "40px",

                minHeight: "400px",

                boxShadow: "0px 7px 0px #00c8d5",
              }}
              className="row"
            >
              <div className="col-md-3 d-flex justify-content-center align-items-center">
                <img
                  style={{}}
                  src={require("../../assets/imgs/subjectsBanner/boxes.png")}
                  alt="boxes"
                />
              </div>
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <p
                  className="p-subject"
                  style={{
                    color: "#B864ED",
                    fontWeight: "600",
                    fontFamily: "Inter",
                    fontSize: "70px",
                    textAlign: "center",
                  }}
                >
                  {t("subjects-banner-1")}
                  <br />{" "}
                  <span style={{ color: "#FFB954" }}>
                    {t("subjects-banner-2")}
                  </span>
                </p>
              </div>

              <div className="col-md-3 d-flex justify-content-center align-items-center">
                <img
                  src={require("../../assets/imgs/subjectsBanner/rocketlaunch.png")}
                  alt="rocketlaunch"
                />
              </div>
            </div>
          </div>
          {/* <div
            className="flex-md-row flex-column justify-content-center align-items-center"
            style={{
              width: "80%",
              marginTop: "50px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="btn px-5 py-1 mb-3"
                style={{
                  backgroundColor: "#ECEBE9",
                  borderRadius: "50px",
                  width: "90%",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  color: "#6A655C",
                  justifyContent: "center",
                }}
              >
                {t("subjects-vd-btn")}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="btn px-5 py-1 mb-3"
                style={{
                  backgroundColor: "#00C8D5",
                  borderRadius: "50px",
                  width: "90%",
                  fontSize: "16px",
                  fontWeight: "900",
                  fontFamily: "Roboto",
                  color: "#ffffff",
                }}
              >
                {t("subjects-mt-btn")}
              </button>
            </div>
            <div
              style={{
                width: "5%",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="btn mb-3"
                style={{
                  backgroundColor: "#ECEBE9",
                  borderRadius: "50px",
                  height: "50px",
                  fontSize: "16px",
                  fontWeight: "900",
                  fontFamily: "Roboto",
                  color: "#6A655C",
                }}
              >
                <FontAwesomeIcon className="fs-3 icon" icon={faFilter} />
              </button>
            </div>
            <div
              style={{
                width: "55%",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <input
                style={{
                  backgroundColor: "#eaeaea",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                }}
                className="form-control mb-3"
                type="text"
                placeholder="Search"
                aria-label="Search"
              />
            </div>
          </div> */}
          {isLoading ? (
            <PageReload />
          ) : isError ? (
            <div className="text-danger fs-5 fw-bold my-4 w-50 m-auto">
              {t("subject-err")}
            </div>
          ) : message ? (
            <>
              <p
                className="fs-5 fw-bold my-4 w-50 m-auto"
                style={{ color: "#00C8D5" }}
              >
                {message}
              </p>
              <a
                href={link}
                target="_blank"
                className="btn btn-primary d-block"
                style={{ width: "150px", marginLeft: "350px" }}
              >
                Pay
              </a>
            </>
          ) : Subjects.length === 0 ? (
            <div className=" fs-5 fw-bold my-4 w-50 m-auto">
              {t("subject-no-more")}
            </div>
          ) : (
            <Container
              className="px-5"
              style={{
                marginTop: "50px",
                marginBottom: "100px",
                width: "100%",
              }}
            >
              <Row className="g-4">
                {Subjects.map((element) => (
                  <Col md={4} key={element.id}>
                    <Link
                      onClick={() => dispatch(setCurrentSubject(element.name))}
                      to={`/lessons/${element.id}`}
                      className="nav-link"
                    >
                      <Subject subjectData={element} />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subjects;
