import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import "./ActiveVideo.scss";

const ActiveVideo = () => {
  const { activeVideo } = useSelector((state) => state.units);

  return (
    <>
      {activeVideo.video ? (
        <div className="active-video">
          <ReactPlayer
            width="100%"
            height="50vh"
            controls={true}
            playing={false}
            url={`https://newbrains-edu.com/public/middle/lessons/videos/${activeVideo.video}`}
          />
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: "50px",
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  width: "12px",
                  height: "48px",
                  borderRadius: "50px",
                  backgroundColor: "#FFB951",
                  marginRight: "20px",
                }}
              ></div>
              <h1>{activeVideo.name}</h1>
            </div>
            <div>
              <p>
                he if clause tells you the condition (If you study hard) and the
                main clause tells you the result (you will pass your exams). The
                order of the clauses does not change the meaning. Conditional
                sentences are often divided into different types.{" "}
              </p>
            </div>
          </div>
        </div>
      ) : (
        "not found"
      )}
    </>
  );
};

export default ActiveVideo;
