import TeacherSlide from "../../components/teacherCompinents/teacherSlide/TeacherSlide";
import TeacherSearch from "../../components/teacherCompinents/teacherSearch/teacherSearch";
import TeacherComponent from "../../components/teacherCompinents/teacherComponent/TeacherComponent";
import Footer from "../../components/general/footer/Footer";
import Navbar from "../../components/general/navbar/Navbar";
import axios from "axios";
import { useEffect, useState } from "react";
import PageReload from "../../components/general/pageReload/PageReload";
import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";

import { Row, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Teachers = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getAllTeachers = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const { data } = await axios.get(
        "https://newbrains-edu.com/public/middle/api/getAllTeachers"
      );
      setTeachers([...data.result]);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTeachers();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <Navbar />
      </div>
      <div style={{ width: "100%" }}>
        <LoginNavbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderRadius: "40px",
              width: "80%",
              height: "400px",

              boxShadow: "0px 7px 0px #00c8d5",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src={require("../../assets/imgs/teacher/teacherBanner.png")}
              alt="boxes"
            />
            {/* <h1
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "72px",
                position: "realtive",
                top: "22%",
                left: "48%",
                color: "white",
                border: "0.3px solid #000000;",
                textShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);",
              }}
            >
              Our Teachers
            </h1> */}
          </div>

          {isLoading ? (
            <PageReload />
          ) : isError ? (
            <div className="text-danger fs-5 fw-bold my-4">
              {t("subject-err")}
            </div>
          ) : teachers.length === 0 ? (
            <div className=" fs-5 fw-bold my-4">No teachers found</div>
          ) : (
            <Container
              className="px-5"
              style={{
                marginTop: "50px",
                marginBottom: "100px",
                width: "100%",
              }}
            >
              <Row className="g-4" style={{}}>
                <TeacherComponent teachers={teachers} />
              </Row>
            </Container>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Teachers;
