import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";
import Sidebar from "../../components/general/sidebar/Sidebar";
import Wrapper from "../../components/general/Wrapper/Wrapper";
import SettingControls from "../../components/settingComponent/settingControls/SettingControls";
import Settinginfo from "../../components/settingComponent/settingInfo/Settinginfo";
import SettingPassword from "../../components/settingComponent/settingPassword/SettingPassword";
import Navbar from "../../components/general/navbar/Navbar";
import Footer from "../../components/general/footer/Footer";
import { useState } from "react";

const Profile = () => {
  const [isPersonal, setIsPersonal] = useState(true);
  return (
    <section
      style={{
        background: 'url("/imgs/settings/settingsBK.png") no-repeat',
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <div
        className="subjects"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          <Navbar />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <LoginNavbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              className="123 flex-md-row flex-column "
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="me-md-5 mb-4 mb-md-0">
                <button
                  style={{
                    backgroundColor: "#ECEBE9",

                    padding: "8px 16px",
                    borderRadius: "50px",
                    marginRight: "30px",
                  }}
                  onClick={() => setIsPersonal(true)}
                >
                  Personel Information
                </button>
                <button
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "#00C8D5",
                    color: "white",
                    borderRadius: "50px",
                  }}
                  onClick={() => setIsPersonal(true)} // change me to false if you wanna activate!
                >
                  Password
                </button>
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: "#F44580",
                    color: "white",
                    padding: "8px 16px",
                    borderRadius: "50px",
                  }}
                >
                  Log Out
                </button>
              </div>
            </div>
            {isPersonal ? <Settinginfo /> : <SettingPassword />}
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default Profile;
