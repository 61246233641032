import {
  faBars,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  faHouse,
  faUser,
  faCircleInfo,
  faRightFromBracket,
  faGear,
  faPaperclip,
  faBook,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import "./navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { setOpenMenu } from "../../../store/openMenuSlice";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TranslationMenu from "../transaltionMenu/TranslationMenu";
import { HashLink } from "react-router-hash-link";
import { faFlag, faGrinStars } from "@fortawesome/free-regular-svg-icons";
import Sidebar from "../sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import axios from "axios";

const Navbars = () => {
  const navigate = useNavigate();
  const { openMenu } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = async () => {
    let token = localStorage.getItem("heighNewbrainsToken");
    try {
      const { data } = await axios.post(
        "https://newbrains-edu.com/api/auth/userLogout",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    localStorage.removeItem("heighNewbrainsToken");
    navigate("/");
  };

  return (
    <section className="navbar-section navbar fixed-top">
      <Container>
        <div>
          <Link to={"/"} className="nav-link">
            <div>
              <img
                style={{ width: "80%", height: "100%" }}
                src="/imgs/navbar/logo.png"
                alt="logo"
              />
            </div>
          </Link>
        </div>
        <Button
          variant="primary"
          onClick={handleShow}
          className="navbar-toggler bg-transparent border-0"
        >
          <span className="navbar-toggler-icon"></span>
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <Link to={"/"} className="nav-link">
                <div>
                  <img
                    style={{ width: "80%", height: "100%" }}
                    src="/imgs/navbar/logo.png"
                    alt="logo"
                  />
                </div>
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body">
            <Link to={"/"} className="nav-link nav-item">
              <FontAwesomeIcon
                className="fs-3 icon"
                style={{ color: "inherit" }}
                icon={faHouse}
              />

              <span className="tabText" role="button">
                {t("navbar-our-home")}
              </span>
            </Link>
            {/* <HashLink to={"/#about-us"} className="nav-item active">
              <FontAwesomeIcon
                className="fs-3 icon"
                style={{ color: "inherit" }}
                icon={faFlag}
              />

              <span className="tabText" role="button">
                {t("navbar-about-us")}
              </span>
            </HashLink> */}

            <Link to={"/teachers"} className="nav-link nav-item">
              <FontAwesomeIcon
                className="fs-3 icon"
                style={{ color: "inherit" }}
                icon={faLeaf}
              />

              <span className="tabText" role="button">
                {t("navbar-our-Teachers")}
              </span>
            </Link>
            {localStorage.getItem("heighNewbrainsToken") ? (
              <Link to={"/subjects"} className="nav-link nav-item">
                <FontAwesomeIcon
                  className="fs-3 icon"
                  style={{ color: "inherit" }}
                  icon={faBook}
                />

                <span className="tabText" role="button">
                  {t("navbar-our-subjects")}
                </span>
              </Link>
            ) : (
              <></>
            )}

            {localStorage.getItem("heighNewbrainsToken") ? (
              <Link to={"/test/1"} className="nav-link nav-item">
                <FontAwesomeIcon
                  className="fs-3 icon"
                  style={{ color: "inherit" }}
                  icon={faPaperclip}
                />

                <span className="tabText" role="button">
                  {t("navbar-our-test")}
                </span>
              </Link>
            ) : (
              <></>
            )}

            {localStorage.getItem("heighNewbrainsToken") ? (
              <Link to={"/profile"} className="nav-link nav-item">
                <FontAwesomeIcon
                  className="fs-3 icon"
                  style={{ color: "inherit" }}
                  icon={faUser}
                />

                <span className="tabText" role="button">
                  {t("navbar-our-account")}
                </span>
              </Link>
            ) : (
              <></>
            )}

            {/* <HashLink className="nav-item" to={"/#start-conversition"}>
              <FontAwesomeIcon
                className="fs-3 icon"
                style={{ color: "inherit" }}
                icon={faFlag}
              />
              <span className="tabText" role="button">
                {t("navbar-contact-us")}
              </span>
            </HashLink> */}

            <div
              style={{
                marginTop: "200px",
                width: "100%",
                height: "250px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="offerBanner">
                <p className="mainOfferText">{t("navbar-promo-1")}</p>
                <p className="subOfferText" style={{ fontWeight: "bold" }}>
                  {t("navbar-promo-2")}
                </p>

                <button
                  style={{ zIndex: "111", fontWeight: "bold" }}
                  className="btn offerButton"
                >
                  {t("navbar-promo-3")}
                </button>

                <img
                  style={{
                    position: "relative",
                    bottom: "100px",
                    left: "175px",
                  }}
                  src={require("../../../assets/imgs/sideNavbar/rocket.png")}
                  alt="rocket"
                />
              </div>
              {/* change me please! */}
              <div role="button" style={{ zIndex: 1 }}>
                <Link to={"/teachers"} className="stngBtn">
                  <FontAwesomeIcon
                    className="fs-3 icon"
                    style={{ color: "white" }}
                    icon={faGear}
                  />

                  <span className="tabText" role="button">
                    {t("navbar-our-settings")}
                  </span>
                </Link>
              </div>
            </div>

            <>
              <div className="">
                <div className="d-flex justify-content-end align-items-center">
                  {localStorage.getItem("heighNewbrainsToken") ? (
                    <>
                      <div className="">
                        <li role={"button"} className="mb-4">
                          <NavLink
                            style={{
                              textDecoration: "none",
                              display: "flex",
                              alignItems: "center",
                            }}
                            className={({ isActive }) =>
                              isActive
                                ? "active-item text-gray2"
                                : "unactive-item text-gray2"
                            }
                            to="/profile"
                          >
                            <span className="mx-2">
                              <FontAwesomeIcon icon={faGear} />
                            </span>
                            <p>{t("side-bar-setting")}</p>
                          </NavLink>
                        </li>

                        <li
                          role={"button"}
                          className="d-flex align-items-center text-gray2"
                        >
                          <span className="mx-2">
                            <FontAwesomeIcon icon={faRightFromBracket} />
                          </span>
                          <p onClick={handleLogout}>{t("side-bar-logout")}</p>
                        </li>
                      </div>
                      <img
                        src={
                          user.image
                            ? "https://newbrains-edu.com/profileImages/users/" +
                              user.image
                            : "/imgs/navbar/user.webp"
                        }
                        alt="user"
                        style={{ width: "55px", height: "55px" }}
                        className="rounded-circle mx-4"
                        role="button"
                        onClick={() => navigate("/subjects")}
                      />
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <Link to={"/login"} className="nav-link">
                          <div
                            role="button"
                            className="fw-bold navbar-log-in text-blue p-2 btn-reduis"
                          >
                            {t("navbar-log-in")}
                          </div>
                        </Link>
                        <Link to={"/signup"}>
                          <div
                            role="button"
                            className="text-blue navbar-sign-up fw-bold border-blue p-2 px-4 btn-reduis mx-4"
                          >
                            {t("navbar-sign-up")}
                          </div>
                        </Link>
                      </div>
                    </>
                  )}
                  <div>
                    {" "}
                    <TranslationMenu />{" "}
                  </div>
                </div>
              </div>

              {/* <Col className="col-6 d-lg-none">
            <div
              onClick={(e) => e.stopPropagation()}
              className="d-flex justify-content-end position-relative align-items-center"
            >
              {localStorage.getItem("heighNewbrainsToken") ? (
                <>
                  <img
                    src={
                      user.image
                        ? "https://newbrains-edu.com/profileImages/users/" +
                          user.image
                        : "/imgs/navbar/user.webp"
                    }
                    alt="user"
                    style={{ width: "55px", height: "55px", cursor: "pointer" }}
                    className="rounded-circle"
                    onClick={() => dispatch(setOpenMenu(true))}
                  />
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    onClick={() => dispatch(setOpenMenu(true))}
                    role="button"
                    className="fs-4"
                    icon={faBars}
                  />
                </>
              )}

              <div
                className="position-absolute bg-white border py-3 rounded mx-4"
                style={{
                  marginTop:
                    localStorage.getItem("heighNewbrainsToken") && "30px",
                  display: openMenu ? "block" : "none",
                  top: "30px",
                  zIndex: "99",
                  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.3)",
                  width: "150px",
                }}
              >
                {localStorage.getItem("heighNewbrainsToken") ? (
                  <Link to="/subjects" className="nav-link">
                    <div
                      className="fw-bold view-profile p-1 text-center w-75 mb-2 m-auto"
                      role="button"
                    >
                      {t("navbar-viewprofile")}
                    </div>
                  </Link>
                ) : (
                  ""
                )}

                <p>
                  <HashLink
                    onClick={() => dispatch(setOpenMenu(false))}
                    to={"/#about-us"}
                  >
                    <span
                      href="#about-us"
                      role="button"
                      className="nav-item fw-bold py-2 text-center text-black d-block"
                    >
                      {t("navbar-about-us")}
                    </span>
                  </HashLink>
                </p>
                <Link
                  to={"/teachers"}
                  className="nav-link"
                  onClick={() => dispatch(setOpenMenu(false))}
                >
                  <p
                    role="button"
                    className="nav-item  fw-bold py-2 text-center text-ramad "
                  >
                    {t("navbar-our-Teachers")}
                  </p>
                </Link>
                <p>
                  <HashLink
                    onClick={() => dispatch(setOpenMenu(false))}
                    to={"/#start-conversition"}
                  >
                    <span
                      role="button"
                      className="nav-item  fw-bold py-2 text-center pb-2 text-black d-block"
                    >
                      {t("navbar-contact-us")}
                    </span>
                  </HashLink>
                </p>

                {localStorage.getItem("heighNewbrainsToken") ? (
                  ""
                ) : (
                  <div className="border-top">
                    <Link to={"/signup"} className="nav-link">
                      <p
                        role="button"
                        className="nav-item fw-bold nav-item-log py-2 text-blue d-flex justify-content-around align-items-center"
                      >
                        <span className="mx-2">{t("navbar-sign-up")}</span>
                        <FontAwesomeIcon
                          className="d-sm-block mt-1"
                          icon={faArrowRightToBracket}
                        />
                      </p>
                    </Link>
                    <Link to={"/login"} className="nav-link">
                      <p
                        role="button"
                        className="nav-item fw-bold nav-item-log py-2 py-1  text-blue d-flex justify-content-around align-items-center"
                      >
                        <span className="mx-2">{t("navbar-log-in")}</span>
                        <FontAwesomeIcon
                          className="d-sm-block mt-1"
                          icon={faArrowRightToBracket}
                        />
                      </p>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col> */}
            </>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </section>
  );
};
export default Navbars;
