import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";
import Sidebar from "../../components/general/sidebar/Sidebar";
import Wrapper from "../../components/general/Wrapper/Wrapper";
import Quiz from "../../components/quiz/Quiz";
import { getUQuizess } from "../../store/quizSlice";
import PageReload from "../../components/general/pageReload/PageReload";
import { setIsEnd, defaultTotalSubmited } from "../../store/showResultSlice";
import LessonsTopPart from "../../components/lessonsComponent/lessonsTopPart/LessonsTopPart";
import Navbar from "../../components/general/navbar/Navbar";

const Test = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const { isLoading } = useSelector((state) => state.quiz);

  useEffect(() => {
    dispatch(getUQuizess(params.id));
    dispatch(setIsEnd(false));
    dispatch(defaultTotalSubmited());
  }, []);

  return (
    <div
      style={{
        background: 'url("/imgs/settings/settingsBK.png") no-repeat',
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div>
        <Navbar />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <LoginNavbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <div className="fs-bold fs-5 mx-4">No Quizzess yet !</div>
          ) : (
            <div style={{ width: "80%" }}>
              <LessonsTopPart />
              <Quiz />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Test;
