import "./Login.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import SocialSign from "../../components/general/socialsign/SocialSign";
import LoginForm from "../../components/LoginComponent/LoginForm";
import { useTranslation } from "react-i18next";
import SignTopPart from "../../components/general/signTopPart/SignTopPart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="login">
      <div className="leftPart">
        <SignTopPart />
        <div className="signWapper">
          <h1 style={{ width: "100%", paddingLeft: "40px" }}>
            {t("log-in-form-header")}
          </h1>
          {/*
          <div className="Buttons">
            <div
              role="button"
              onClick={() => console.log("do somthing please Google")}
              className="SocialBtn"
            >
              <FontAwesomeIcon className="fs-3 icon" icon={faGoogle} />
              {t("log-in-button-google")}
            </div>
            <div style={{ width: "7%" }}></div>
            <div
              role="button"
              onClick={() => console.log("do somthing please Facebook")}
              className="SocialBtn"
            >
              <FontAwesomeIcon className="fs-3 icon" icon={faFacebook} />
              {t("log-in-button-facebook")}
            </div>
          </div>
          */}
          <div className="orClass">
            <p>- OR -</p>
          </div>
          <LoginForm />
          <div className="option">
            {t("log-in-form-paragraph")}
            <span>
              <Link to="/signup"> {t("log-in-form-link")}</Link>
            </span>
          </div>
        </div>
      </div>

      <div className="rightPart">
        <img
          style={{
            position: "relative",
            right: "100px",
            top: "50px",
            zIndex: "111",
          }}
          src={require("../../assets/imgs/login/computers.png")}
          alt="computers"
        />
        {/*<img
          style={{
            position: "absolute",
            bottom: "0",
            left: "350px",
            width: "25%",
          }}
          src={require("../../assets/imgs/signup/Hill.png")}
          alt="Visa"
        /> */}
      </div>
    </div>
  );
};

export default Login;
