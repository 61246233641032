import "./SettingPassword.scss";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingBtn from "../../general/btnReload/BtnReload";
import { getUser } from "../../../store/currentUser";
import { useRef } from "react";
import { toast } from "react-toastify";

const SettingPassword = () => {
  const currentPassword = useRef();
  const newPassword = useRef();
  const verifyPassword = useRef();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.currentUser);
  const [fileUrl, setFileUrl] = useState();

  const [image, setImage] = useState("");
  const [userData, setUserData] = useState({
    currentPassword: "",
    newPassword: "",
    verifyPassword: "",
  });

  const [isPost, setIsPost] = useState(false);
  const [errArr, setErrArr] = useState({});

  const postData = (e) => {
    // formData.append(e.target.name, e.target.value);
    let usr = { ...userData };
    usr[e.target.name] = e.target.value;
    setUserData({ ...usr });
  };

  const postDataForFile = async (e) => {
    setImage(e.target.files[0]);
    setFileUrl(URL.createObjectURL(e.target.files[0]));

    // formData.append("image");
  };

  const clearData = () => {
    currentPassword.current.value = "";
    newPassword.current.value = "";
    verifyPassword.current.value = "";
  };

  const getData = async () => {
    let formData = new FormData();
    formData.append("name", userData.currentPassword);
    formData.append("phone", userData.newPassword);
    formData.append("parent_phone", userData.verifyPassword);

    setIsPost(true);

    try {
      const { data } = await axios.post(
        "https://newbrains-edu.com/public/middle/api/auth/userUpdateProfile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "heighNewbrainsToken"
            )}`,
            "content-type": "multipart/form-data",
          },
        }
      );
      dispatch(getUser());
      setIsPost(false);
      setErrArr({});
      clearData();
      toast.success(`profile updated`, { position: "bottom-left" });
      console.log(data);
    } catch (error) {
      console.log(error);
      setIsPost(false);
      setErrArr({ ...JSON.parse(error.response.data) });
    }
  };

  const submitData = async (e) => {
    e.preventDefault();
    getData();
  };

  return (
    <div className="setting-info">
      <form className="mt-4" onSubmit={submitData}>
        <Row>
          <Col
            className="col-12"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="setting-name">
              <input
                onChange={(e) => postData(e)}
                name="currentPassword"
                placeholder="Current Password"
                type="password"
                ref={currentPassword}
              />
              <div className="text-danger" style={{ fontSize: "14px" }}>
                {errArr.name && errArr.name[0]}
              </div>
            </div>
            <div className="setting-name">
              <input
                onChange={(e) => postData(e)}
                name="newPassword"
                placeholder="New Password"
                type="password"
                ref={newPassword}
              />
              <div className="text-danger" style={{ fontSize: "14px" }}>
                {errArr.name && errArr.name[0]}
              </div>
            </div>
            <div>
              <input
                onChange={(e) => postData(e)}
                name="parent_email"
                placeholder="Verify Password"
                type="password"
                ref={verifyPassword}
              />
              <div className="text-danger" style={{ fontSize: "14px" }}>
                {errArr.name && errArr.parent_email[0]}
              </div>
            </div>

            <div className="btns-setting-send-data mt-4 d-flex justify-content-end">
              {/* <button className='light-button mx-3'>CANCEL</button> */}
              <button className="primary-button" style={{ width: "200px" }}>
                {isPost ? (
                  <LoadingBtn />
                ) : (
                  <span>{t("profile-password-form-button-save")}</span>
                )}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SettingPassword;
