import { useTranslation } from "react-i18next";
import "./subject.scss";

const Subject = ({ subjectData }) => {
  const { t } = useTranslation();
  const { name, teacher_name, teacher_image, teacher_id, id, image } =
    subjectData;
  const teacherImageUrl =
    "https://newbrains-edu.com/public/middle/profileImages/teachers/";
  const SubjectImageUrl = "https://newbrains-edu.com/public/middle/subjects/";

  return (
    <div className="card" style={{ width: "18rem" }}>
      <img
        className="card-img-top mb-3"
        src={SubjectImageUrl + image}
        alt={name}
      />
      <div className="card-body">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              width: "80%",
            }}
          >
            <h5 className="card-title">{name}</h5>
          </div>
          <div
            style={{
              backgroundColor: "#ffb951",
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
              width: "100px",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "3px",
            }}
          >
            <p>2h 22m</p>
          </div>
        </div>
        <p className="card-text">
          {/* Some quick example text to build on the card title and make up the
          bulk of the card's content. */}
        </p>
      </div>
      <a
        href="#"
        className="btn "
        style={{
          width: "50%",
          margin: "15px",
          backgroundColor: "#00c8d5",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {t("subject-start")}
      </a>
    </div>

    // <div className='subject'>
    //   <img
    //     className='subject-img w-100'
    //     style={{height:"150px"}}
    //     src= {SubjectImageUrl + image}
    //     alt={name}
    //   />
    //   <div className='subject-info'>
    //     <div className='stuNums'>
    //       <img src='./imgs/subjects/studentsIcon.png' alt='' />
    //       <span>700</span>
    //       Students
    //     </div>
    //     <div className='time'>
    //       <img
    //         className='time__icon'
    //         src='./imgs/subjects/timeIcon.png'
    //         alt=''
    //       />
    //       <span className='time__num'>2h 20m</span>
    //     </div>
    //   </div>
    //   <h4 className='name'>{name}</h4>
    //   <div className='teacher-info'>
    //   <img src={teacherImageUrl + teacher_image} alt={teacher_image}
    //     style={{width:"50px", height:"50px"}}
    //     className="rounded-circle"
    //   />
    //         <p>Mr. {teacher_name}</p>
    //   </div>
    // </div>
  );
};

export default Subject;
