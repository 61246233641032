import LoginNavbar from "../../components/general/lgoinNavbar/LoginNavbar";
import Wrapper from "../../components/general/Wrapper/Wrapper";
import ActiveVideo from "../../components/lessonsComponent/ActiveVideo/ActiveVideo";
import Sidebar from "../../components/general/sidebar/Sidebar";
import VideoControls from "../../components/lessonsComponent/videoControls/VideoControls";
import "./Lessons.scss";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnits } from "../../store/unitsSlice";
import PageReload from "../../components/general/pageReload/PageReload";
import LessonsTopPart from "../../components/lessonsComponent/lessonsTopPart/LessonsTopPart";
import Navbar from "../../components/general/navbar/Navbar";
import Footer from "../../components/general/footer/Footer";
import { HiOutlinePlay } from "react-icons/hi2";
import { CiPause1, CiPlay1 } from "react-icons/ci";
import LessonsAccordion from "../../components/lessonsComponent/lessonsAccoredion/LessonsAccordion";
import { setCurrentLessons, setCurrentVideo } from "../../store/unitsSlice";

const Lessons = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.units);

  const { units, activeVideoIndex, currentUnit } = useSelector(
    (state) => state.units
  );

  const handleLessonClick = (lesson, currentVideo) => {
    dispatch(setCurrentLessons(lesson));
    dispatch(setCurrentVideo(currentVideo));
  };

  useEffect(() => {
    dispatch(getUnits(params.id));
  }, []);

  return (
    <section
      style={{
        background: 'url("/imgs/videos/videoBK.png") no-repeat',
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      {isLoading ? (
        <PageReload />
      ) : (
        <div
          className="subjects"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <Navbar />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <LoginNavbar />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <LessonsTopPart />
                <div
                  className="video-wrapper"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div>
                    <ActiveVideo />

                    <VideoControls />
                  </div>
                  <div
                    style={{
                      // width: "30%",
                      // height: "20%",
                      backgroundColor: "transparent",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "20px",
                      color: "white",
                    }}
                  >
                    {units.map((unit, Unitsindx) => (
                      <ul className="list-unstyled p-0 ul-item">
                        {unit.lessons.map((lesson, lessonsIndx) => (
                          <li
                            onClick={() =>
                              handleLessonClick(Unitsindx, lessonsIndx)
                            }
                            style={
                              activeVideoIndex == lessonsIndx &&
                              currentUnit == unit.name
                                ? { color: "white" }
                                : { color: "black" }
                            }
                            key={lesson.id}
                            className={Unitsindx}
                            id={lessonsIndx}
                          >
                            <div
                              className="videos-container mx-md-4 mx-2"
                              style={{
                                backgroundColor: "#02c7d4",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <button className="play-container">
                                {activeVideoIndex == lessonsIndx &&
                                currentUnit == unit.name ? (
                                  <CiPause1 size="2.5rem" color="white" />
                                ) : (
                                  <CiPlay1 size="2.5rem" color="white" />
                                )}
                              </button>

                              <p className="text1">{lesson.name}</p>
                              {/* <p className="text2">test for description</p> */}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </section>
  );
};

export default Lessons;
