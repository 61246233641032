import {
  faChevronRight,
  faChevronLeft,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "./lessonsTopPart.scss";

const LessonsTopPart = () => {
  const params = useParams();
  const location = useLocation();
  const { lang } = useSelector((state) => state.currentLang);
  const { subject } = useSelector((state) => state.currentSubject);
  const { currentUnit, activeVideo } = useSelector((state) => state.units);
  const { currentQuiz } = useSelector((state) => state.quiz);

  useEffect(() => {
    subject && localStorage.setItem("subject", subject);
    currentUnit && localStorage.setItem("currentUnit", currentUnit);
    activeVideo.name && localStorage.setItem("activeVideo", activeVideo.name);
    currentQuiz.title && localStorage.setItem("currentQuiz", currentQuiz.title);
  }, []);

  return (
    <div
      className=" mx-0  mx-md-5 mb-4 align-items-center "
      style={{
        display: "flex",
        flexDirection: "row",
        // width: location.pathname == `/test/${params.id}` ? "380px" : "300px",
        width: "100%",
        color: "#F44580",
      }}
    >
      {/* <FontAwesomeIcon className="fs-5" icon={faNoteSticky} /> */}
      <div className="normalTab">
        <p>{subject ? subject : localStorage.getItem("subject")}</p>
      </div>
      <FontAwesomeIcon icon={lang === "en" ? faChevronRight : faChevronLeft} />
      <div className="normalTab">
        <p>{currentUnit ? currentUnit : localStorage.getItem("currentUnit")}</p>
      </div>
      <FontAwesomeIcon icon={lang === "en" ? faChevronRight : faChevronLeft} />
      <div className="normalTab">
        <p>
          {activeVideo.name
            ? activeVideo.name
            : localStorage.getItem("activeVideo")}
        </p>
      </div>
      {location.pathname == `/test/${params.id}` && (
        <>
          <FontAwesomeIcon
            icon={lang === "en" ? faChevronRight : faChevronLeft}
          />
          <div className="activeTab">
            <p>
              {currentQuiz.title
                ? currentQuiz.title
                : localStorage.getItem("currentQuiz")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default LessonsTopPart;
