import { useSelector } from "react-redux";

const Wrapper = ({ children }) => {
  const { openSidebar, sidebarWidth, screeSize } = useSelector(
    (state) => state.sidebar
  );
  const { lang } = useSelector((state) => state.currentLang);
  return <div style={{}}>{children}</div>;
};

export default Wrapper;
